import React,{ useState,useCallback,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { 
  AppBar, 
  Toolbar,   
  IconButton,   
  Container, 
  Box, 
  ToggleButton, 
  ToggleButtonGroup, 
  TextField, 
  MenuItem,
  Select,
  InputAdornment ,
  useMediaQuery,
} from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker   } from "@mui/x-date-pickers";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DateTime } from 'luxon';

import { ThemeContext } from "./ThemeContext";
import Fixture  from "./Fixture";
import SportFlixInfo from "./SportFlixInfo.js";

export default function App() {
  const { darkMode, setDarkMode } = React.useContext(ThemeContext);
  const [selectedDate, setSelectedDate] = useState(dayjs(DateTime.now().toFormat('yyyy-MM-dd')));
  const [open, setOpen] = useState(false);
  const [view, setView] = useState("all");  

  const [selectedLeague, setSelectedLeague] = useState("All league");
  const [allleague, setAllleague] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {  
    setSearchTerm(event.target.value);
  };

  const handleChangeView = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };  

  const handleleagueChange = (event) => {
    setSelectedLeague(event.target.value);
  };

  const handleAllleagueFromChild = useCallback((data) => {     
    const uniqueLeage = data?[...new Set(data.map(item => item.league.name))]:[]; 
    setAllleague(uniqueLeage);
  }, []); 

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));  // Mobile

  useEffect(() => {
    // Dynamically load the ad script
    const script = document.createElement("script");
    script.src = "//servedby.aqua-adserver.com/asyncjs.php";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);
  
  return (
    <Router> 
      <Box        
        sx={{
          width: "100%",          
          backgroundColor: "#0e0e16",
          textAlign: "center",
          padding: { xs: "5px", sm: "20px", md: "30px" },
          position: "fixed",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0,
          zIndex: 1400, // Ensure it's above the AppBar
          height: { xs: "120px", sm: "180px", md: "240px" },
          "img":{
            maxWidth: "100%",
            height: "auto",
            display: "block",
            margin: "0 auto",
          }
        }}
      >
        <ins
          data-revive-zoneid="11586"
          data-revive-id="fa8c6c9da7f33852f7097c4a94da1070"
        ></ins>
      </Box>     
      <AppBar position="fixed" sx={{ top: { xs: "120px", sm: "180px", md: "240px" }, zIndex: 1300 }}>
        <Container sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Toolbar sx={{ padding: "0 0" }}>
          {/* <Typography component={Link} to="/" variant="h6" sx={{ flexGrow: 1 }}>Livescore App</Typography> */}
          <Box  display="flex"
                direction="row"
                alignItems="center" 
                gap={1}
                sx={{ flexGrow: 1, px: 1}}
          >
            <Select
              value={selectedLeague}
              onChange={handleleagueChange}
              size="small"          
              variant="outlined"
              sx={{ width: isMobile?50:250,
                borderRadius: "16px",
                '& .MuiSelect-select': {
                  color: 'white', // Text color
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Border color
                },
                '& .MuiInputLabel-root': {
                  color: 'white', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white', // Focused label color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Border color on hover
                } 
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 350, // Limit height for scrolling
                    overflowY: "auto",
                  },
                },
              }}
            >
              <MenuItem value="All league" selected>All league</MenuItem>
              
              {allleague.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}              
            </Select>            
            <TextField 
              value={searchTerm}
              onChange={handleSearchChange}                         
              size="small" 
              placeholder="Search..."
              variant="outlined" 
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color:"white"}}/>
                    </InputAdornment>
                  ),
                },
              }}             
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px", // Rounded corners                  
                },
                '& .MuiInputBase-root': {
                  color: 'white', // Text color
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white', // Border color
                },
                '& .MuiInputLabel-root': {
                  color: 'white', // Label color
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white', // Focused label color
                },
                width: isMobile?150:250,
               
              }}
            />
              
          </Box>         
          
          
          {/* <Button color="inherit" component={Link} to="/">Home</Button>
          <Button color="inherit" component={Link} to="/about">About</Button> */}
          <Box display="flex"
                direction="row"
                alignItems="center" 
                gap={1}                
                >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleChangeView}
              
              size="small"
              sx={{
                borderRadius: "14px", // Rounded edges for the entire group
                overflow: "hidden", // Prevents child elements from breaking the border radius
                border: "1px solid white", // White border
              }}
            >        
              <ToggleButton 
                color="inherit" 
                value="all" 
                sx={{
                  color:"white",
                  '&.Mui-selected': {
                    backgroundColor: '#358fca', // Active color for "All"
                  }
                }}>
                {isMobile?"A":"All"}
              </ToggleButton>
              <ToggleButton 
                color="inherit" 
                value="live"
                sx={{
                  color:"white",
                  '&.Mui-selected': {
                    backgroundColor: '#358fca', // Active color for "All"
                  }
                }}>
                  {isMobile?<FiberManualRecord sx={{ color: "green", fontSize: 16 }} />:"Live"}
                </ToggleButton>
            </ToggleButtonGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker 
                defaultValue={dayjs(DateTime.now().toFormat('yyyy-MM-dd'))}
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue);
                  setOpen(false); // Close after selecting
                }}
                open={open}
                onClose={() => setOpen(false)}
                sx={{display:"none"}}// Hide the default TextField
                
              />           
              <IconButton sx={{color:"white"}}   onClick={() => setOpen(true)} >
                <CalendarTodayIcon />
              </IconButton>
            </LocalizationProvider>
          </Box>
          <IconButton sx={{color:"white"}}  onClick={() => setDarkMode(!darkMode)} >
            {darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Toolbar>
        </Container>
      </AppBar> 
        

      {/* Main Content */}
      <Container         
        sx={{  
          textAlign: "center", 
          mt: 10,
          paddingTop: { xs: "100px", sm: "170px", md: "230px" }, 
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}>
        <Routes>
          <Route path="/" element={
            <Fixture 
              view={view} 
              selectedDate={dayjs(selectedDate).format("YYYY-MM-DD")} 
              handleAllleague={handleAllleagueFromChild}
              selectedLeague={selectedLeague}
              search={searchTerm}
              />} />
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>        
      </Container>
      <SportFlixInfo/>
    </Router>
  );
}
