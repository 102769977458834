import React,{ useEffect} from "react";
import SoccerLineUp from "react-soccer-lineup";
import { Stack, Typography, Chip } from "@mui/material";
import "./lineupwidget.css";

const LineupWidget=({id,formation_home,lineups_home,formation_away,lineups_away})=> {
  
  const lineups_format = (color,formation,lineup) => {
     let team = {
        color: color,
        squad: {}
     }
     let df = [];
     let cdm = [];
     let cm = [];
     let fw = [];
     const parts = formation.split("-");
     if(parts.length === 4){
        const datas = lineup.filter((line)=>line.formation_field !== null).sort((a,b)=>a.formation_field.localeCompare(b.formation_field));     
        for (const data of datas) { 
          if(data.formation_field === "1:1"){
            team = {...team,squad:{...team.squad,gk:{name:data.jersey_number}}}
          }
          if(data.formation_field === "2:1"){
            df.push({name:data.jersey_number});
          }
          if(data.formation_field === "2:2"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "2:3"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "2:4"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:1"){
            cdm.push({name:data.jersey_number});
          }
          if(data.formation_field === "3:2"){
            cdm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:3"){
            cdm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:4"){
            cdm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:1"){
            cm.push({name:data.jersey_number});
          }
          if(data.formation_field === "4:2"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:3"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:4"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "5:1"){
            fw.push({name:data.jersey_number});
          }
          if(data.formation_field === "5:2"){
            fw.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "5:3"){
            fw.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "5:4"){
            fw.unshift({name:data.jersey_number});
          }          
        }
        
        return {
          ...team,
          squad: {
            ...team.squad,
            df: df,
            cdm: cdm,
            cm: cm,
            fw: fw
          }
        }
      }
      if(parts.length === 3){
        const datas = lineup.filter((line)=>line.formation_field !== null).sort((a,b)=>a.formation_field.localeCompare(b.formation_field));     
        for (const data of datas) { 
          if(data.formation_field === "1:1"){
            team = {...team,squad:{...team.squad,gk:{name:data.jersey_number}}}
          }
          if(data.formation_field === "2:1"){
            df.push({name:data.jersey_number});
          }
          if(data.formation_field === "2:2"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "2:3"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "2:4"){
            df.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:1"){
            cm.push({name:data.jersey_number});
          }
          if(data.formation_field === "3:2"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:3"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "3:4"){
            cm.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:1"){
            fw.push({name:data.jersey_number});
          }
          if(data.formation_field === "4:2"){
            fw.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:3"){
            fw.unshift({name:data.jersey_number});
          }
          if(data.formation_field === "4:4"){
            fw.unshift({name:data.jersey_number});
          }          
        }
        
        return {
          ...team,
          squad: {
            ...team.squad,
            df: df,           
            cm: cm,
            fw: fw
          }
        }
      }
    return {}
  }

  useEffect(() => {  
       
    const teams = document.querySelectorAll(`.${id} .teams .team`)
    if(teams.length === 2){   
      lineups_home.filter((line)=>line.formation_field !== null).forEach((line_player, index) => {
        const jersey_number = line_player.jersey_number;
        const image_path = line_player.image_path;     
        
        teams[0].querySelectorAll(".player").forEach((player, index) => {
          const nameElement = player.querySelector(".name");
          if (nameElement && nameElement.textContent.trim() === jersey_number.toString()) {    
            if (image_path !== null && image_path !== "") {        
              player.style.backgroundImage = `url(${image_path})`;
              player.style.backgroundSize = "cover";  // ✅ ตั้งค่าให้ Background ครอบคลุมพื้นที่
              player.style.backgroundPosition = "center"; // ✅ จัดตำแหน่งให้อยู่ตรงกลาง
            }
          }
        });       
      });
      lineups_away.filter((line)=>line.formation_field !== null).forEach((line_player, index) => {
        const jersey_number = line_player.jersey_number;
        const image_path = line_player.image_path;     
        
        teams[1].querySelectorAll(".player").forEach((player, index) => {
          const nameElement = player.querySelector(".name");
          if (nameElement && nameElement.textContent.trim() === jersey_number.toString()) {    
            if (image_path !== null && image_path !== "") {        
              player.style.backgroundImage = `url(${image_path})`;          
              player.style.backgroundSize = "cover";  // ✅ ตั้งค่าให้ Background ครอบคลุมพื้นที่
              player.style.backgroundPosition = "center"; // ✅ จัดตำแหน่งให้อยู่ตรงกลาง
            }
          }
        });       
      });
    }
  }, []);

  return (
    <div className={`${id}`}>
      <Stack direction="row" justifyContent="space-between" spacing={1} mt={1} mb={1}>
        <Typography>{formation_home}</Typography>
        <Typography>{formation_away}</Typography>
      </Stack>
      <SoccerLineUp
        size={"responsive"}
        pattern={"lines"}        
        homeTeam={lineups_format("lightblue",formation_home,lineups_home)}
        awayTeam={lineups_format("red",formation_away,lineups_away)}
      />
      {lineups_home.length>0 &&
        <Stack direction="row" justifyContent="space-between" spacing={1} mt={1}>                      
          <Stack direction="column" alignItems="flex-start" spacing={1}>
            <Typography pb={2}>Lineup</Typography>  
            {lineups_home.filter((line) =>line.formation_position!==null).map((line, index) => (                      
              <Stack key={`home-lineup-${index}`} direction="row" alignItems="center" spacing={1}>
                <Chip label={line.jersey_number} size="small"/>
                <Typography align='left' variant="caption">{line.player_name}</Typography>
              </Stack>                                                              
            ))}  
            <Typography pt={2} pb={2}>Bench</Typography>  
            {lineups_home.filter((line) =>line.formation_position===null).map((line, index) => (                      
              <Stack key={`home-Bench-${index}`} direction="row" alignItems="center" spacing={1}>
                <Chip label={line.jersey_number} size="small"/>
                <Typography align='left' variant="caption">{line.player_name}</Typography>
              </Stack>                                                              
            ))}  
          </Stack>
          <Stack direction="column" alignItems="flex-end" spacing={1}>
            <Typography pb={2}>Lineup</Typography>  
            {lineups_away.filter((line) =>line.formation_position!==null).map((line, index) => ( 
              <Stack key={`away-lineup-${index}`} direction="row" alignItems="center" spacing={1}>                        
                <Typography align='right' variant="caption">{line.player_name}</Typography>  
                <Chip label={line.jersey_number} size="small"/> 
              </Stack> 
            ))}  
            <Typography pt={2} pb={2}>Bench</Typography>  
            {lineups_away.filter((line) =>line.formation_position===null).map((line, index) => (                      
              <Stack key={`away-Bench-${index}`} direction="row" alignItems="center" spacing={1}>                            
                <Typography align='right' variant="caption">{line.player_name}</Typography>
                <Chip label={line.jersey_number} size="small"/>
              </Stack>                                                              
            ))}   
          </Stack>
        </Stack>  
        }          
    </div>
  );
}

export default LineupWidget;