import React from "react";
import { motion } from "framer-motion";
import { Box } from "@mui/material";

const BlinkingDot = () => {
  return (
    <Box
      component={motion.div}
      sx={{
        width: 3,
        height: 3,
        backgroundColor: "#809bce",
        borderRadius: "50%",        
        marginLeft: "3px",
      }}
      animate={{ opacity: [1, 0.2, 1] }}
      transition={{ duration: 1, repeat: Infinity }}
    />
  );
};

export default BlinkingDot;
