import React, { useState, useEffect, useContext } from 'react';
import { 
    Box,
    CircularProgress, 
    Typography,
    Avatar,
    Stack,
    Chip,    
    Button,   
 } from '@mui/material';
import axios from 'axios';
import { ThemeContext } from "./ThemeContext";
import { DateTime } from 'luxon';

const MatchesWidget = ({isMobile, team_home_id, team_away_id}) =>{     
    const { darkMode } = useContext(ThemeContext); // Get theme mode
    const [dataHome, setDataHome] = useState(null); 
    const [dataAway, setDataAway] = useState(null);    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const formatDate = (starting_at) =>{
        const dt = DateTime.fromSQL(starting_at, { zone: 'UTC' }); 
        return isMobile?dt.setZone(DateTime.now().zoneName).toFormat('d LLL yy'):dt.setZone(DateTime.now().zoneName).toFormat("d LLLL ''yy");   
    }

    const isStart = (starting_at) =>{
        const dt1 = DateTime.now().setZone('UTC');
        const dt2 = DateTime.fromSQL(starting_at, { zone: 'UTC' });    
        return dt1>dt2 || dt1.equals(dt2);
    }

    const formatTime = (starting_at) =>{
        const dt = DateTime.fromSQL(starting_at, { zone: 'UTC' }); 
        return dt.setZone(DateTime.now().zoneName).toFormat('HH:mm');   
    }  

    useEffect(() => {
        // Example API call (replace with your own API URL)
        axios
          .post(`${process.env.REACT_APP_API_URL}/matches`,{
                date: DateTime.now().toFormat("yyyy-MM-dd"),
                timezone: DateTime.now().toFormat('ZZ'),               
                team_id: team_home_id
          })          
          .then(response  => {  
            setDataHome(response.data); // Store data in state            
          })
          .catch(error => {
            setError(error); // Handle errors           
          })
          .finally(() => {
            setLoading(false);
          });
      }, []); // Empty dependency array means this effect runs once when the component mounts 
    
    useEffect(() => {
        // Example API call (replace with your own API URL)
        axios
          .post(`${process.env.REACT_APP_API_URL}/matches`,{
                date: DateTime.now().toFormat("yyyy-MM-dd"),
                timezone: DateTime.now().toFormat('ZZ'),               
                team_id: team_away_id
          })          
          .then(response  => {  
            setDataAway(response.data); // Store data in state            
          })
          .catch(error => {
            setError(error); // Handle errors           
          })
          .finally(() => {
            setLoading(false);
          });
      }, []); // Empty dependency array means this effect runs once when the component mounts 

    if (loading) {
        return <CircularProgress />; // Show loading spinner while fetching data
    }

    if (error) {
        return <Typography color="error">Error: {error.message}</Typography>; // Show error if the API call fails
    }
    
    const showData = (data) =>{
        if(data && data.length > 0){
            return data.sort((a, b) => a.starting_at.localeCompare(b.starting_at)).map((match,index) => (             
                <Box
                    key={`h2h-item-${index}`}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    gap={2}
                    mb={4}
                    sx={{ padding: 1, margin: 0, borderBottom: darkMode?'1px solid rgba(255, 255, 255, 0.3)':'1px solid rgba(0, 0, 0, 0.3)' }}
                >
                    {isMobile ? (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{ width: "55%", margin: 0 }}
                    >     
                        <Stack direction="column" alignItems="left" spacing={1} sx={{ width: "60%", margin: 0 }}>
                            <Typography variant="caption" sx={{ textAlign: "left" }}>
                                {match.league.name}
                            </Typography>
                            <Typography variant="caption" sx={{ textAlign: "left" }}>
                                { formatDate(match.starting_at) }
                            </Typography>
                        </Stack>     
                        <Stack direction="column" alignItems="center" spacing={1} sx={{ width: "40%", margin: 0 }}>
                            <Avatar
                            src={match.team_home.image_path}
                            alt={match.team_home.name}
                            sx={{ width: 20, height: 20, borderRadius: "50%",  }}
                            />
                            <Typography variant="caption">
                            {match.team_home.short_code}
                            </Typography>
                        </Stack>
                    </Stack>
                    ) : (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{ width: "45%", margin: 0 }}
                    >     
                        <Stack direction="column" alignItems="left" spacing={1}>
                            <Typography variant="caption" sx={{ textAlign: "left" }}>
                                {match.league.name}
                            </Typography>
                            <Typography variant="caption" sx={{ textAlign: "left" }}>
                                { formatDate(match.starting_at) }
                            </Typography>
                        </Stack>       
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="caption" sx={{ textAlign: "right" }}>
                                {match.team_home.short_code}
                            </Typography>
                            <Avatar
                                src={match.team_home.image_path}
                                alt={match.team_home.name}
                                sx={{ width: 30, height: 30, borderRadius: "50%" }}
                            />
                        </Stack>
                    </Stack>
                    )}
                    
                        
                        {isStart(match.starting_at) ? (
                                          <Chip
                                            label={`${match.team_home.score} - ${match.team_away.score}`}
                                            color="success"
                                            size="small"
                                            sx={{ backgroundColor: "#65a8de", mt: 1, margin: 0 }}
                                          />
                                        ) : (
                                          <Chip
                                            label={`${formatTime(match.starting_at)}`}
                                            color="success"
                                            size="small"
                                            sx={{ backgroundColor: "#65a8de", mt: 1, margin: 0 }}
                                          />
                                        )}       
                    
                    {isMobile ? (
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: "20%"}}
                    >
                        <Avatar
                        src={match.team_away.image_path}
                        alt={match.team_away.name}
                        sx={{ width: 20, height: 20, borderRadius: "50%" }}
                        />
                        <Typography variant="caption" sx={{ textAlign: "center" }}>
                        {match.team_away.short_code}
                        </Typography>
                    </Stack>
                    ) : (
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ width: "45%"}}
                    >
                        <Avatar
                        src={match.team_away.image_path}
                        alt={match.team_away.name}
                        sx={{ width: 30, height: 30, borderRadius: "50%" }}
                        />
                        <Typography variant="caption">{match.team_away.short_code}</Typography>
                    </Stack>
                    )}
                </Box> 
            ))
        }else{
            return "";
        }
    }

    return ( 
        <>        
        <Button variant="contained" disabled fullWidth>            
            <Typography variant="body1">TEAM HOME</Typography>        
        </Button>            
        {showData(dataHome)}
        <Button variant="contained" disabled mt={2} fullWidth>            
            <Typography variant="body1">TEAM AWAY</Typography>        
        </Button> 
        {showData(dataAway)}         
       </>
    );
}

export default MatchesWidget;
