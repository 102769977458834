import React from "react";
import { Box, Stack, Typography, Chip } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

const EventsWidget=({mode, events})=> {
  const event_home_format = (event) =>{
        if(event.addition.includes("Goal")){
            return (  
                    <Stack direction="row" alignItems="center" justifyContent="right" sx={{ width: "45%" }} gap={1}>                                          
                        <Typography variant="caption">                    
                        {event.location === "home" && event.player_name}
                        </Typography> 
                        {event.location === "home" && <SportsSoccerIcon />}
                    </Stack>               
                    )
        } 
        else if(event.addition.includes("Redcard")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="right" sx={{ width: "45%" }} gap={1}>                        
                        <Typography variant="caption">                        
                        {event.location === "home" && event.player_name}
                        </Typography>               
                        {event.location === "home" && <LocalOfferIcon sx={{ color: "#E16A54"}}/>}         
                    </Stack>
                    )
        }
        else if(event.addition.includes("Yellowcard")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="right" sx={{ width: "45%" }} gap={1}>                        
                        <Typography variant="caption">                        
                        {event.location === "home" && event.player_name}
                        </Typography>                       
                        {event.location === "home" && <LocalOfferIcon sx={{ color: "#FADA7A" }}/>} 
                    </Stack>
                    )
        }
        else if(event.addition.includes("Substitution")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="right" sx={{ width: "45%" }} gap={1}>
                        <Typography variant="caption">                       
                        { event.location === "home" && event.player_name}
                        </Typography>                        
                        { event.location === "home" && <SyncIcon/>}                        
                        <Typography variant="caption">                        
                        { event.location === "home" && event.related_player_name}
                        </Typography>
                    </Stack>
                    )
        }
        else{
            return (<Typography variant="caption" sx={{ width: "45%", textAlign: "right"}}>                
                { event.location === "home" && event.related_player_name}
                </Typography>)
        }        
    }
  const event_away_format = (event) =>{
        if(event.addition.includes("Goal")){
            return (  
                    <Stack direction="row" alignItems="center" justifyContent="left" sx={{ width: "45%" }} gap={1}>
                        {event.location === "away" && <SportsSoccerIcon />}                  
                        <Typography variant="caption">                    
                        {event.location === "away" && event.player_name}
                        </Typography> 
                    </Stack>               
                    )
        } 
        else if(event.addition.includes("Redcard")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="left" sx={{ width: "45%" }} gap={1}>
                        {event.location === "away" && <LocalOfferIcon sx={{ color: "#E16A54"}}/>}
                        <Typography variant="caption">                        
                        {event.location === "away" && event.player_name}
                        </Typography>                        
                    </Stack>
                    )
        }
        else if(event.addition.includes("Yellowcard")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="left" sx={{ width: "45%" }} gap={1}>
                        {event.location === "away" && <LocalOfferIcon sx={{ color: "#FADA7A" }}/>}
                        <Typography variant="caption">                        
                        {event.location === "away" && event.player_name}
                        </Typography>                        
                    </Stack>
                    )
        }
        else if(event.addition.includes("Substitution")){
            return (
                    <Stack direction="row" alignItems="center" justifyContent="left" sx={{ width: "45%" }} gap={1}>
                        <Typography variant="caption">                       
                        { event.location === "away" && event.player_name}
                        </Typography>                        
                        { event.location === "away" && <SyncIcon/>}                        
                        <Typography variant="caption">                        
                        { event.location === "away" && event.related_player_name}
                        </Typography>
                    </Stack>
                    )
        }
        else{
            return (<Typography variant="caption" sx={{ width: "45%", textAlign: "left"}}>                
                { event.location === "away" && event.related_player_name}
                </Typography>)
        }        
    }

  return events.sort((a,b)=>b.minute-a.minute).map((event,index)=>(
        <Box
            key={`event-${index}`}
            display="flex"
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"    
            gap={2}      
            sx={{ padding: 1, margin: 0, borderBottom: mode?'1px solid rgba(255, 255, 255, 0.3)':'1px solid rgba(0, 0, 0, 0.3)' }}
        >
            {event_home_format(event)}
            <Stack direction="column">
                <Chip
                    label={`${event.minute}`}
                    color="success"
                    size="small"
                    sx={{ backgroundColor: "#65a8de", mt: 1, margin: 0 }}
                />
                {event.result && 
                    <Typography variant="caption">
                        {event.result}
                    </Typography>
                }
            </Stack>
            {event_away_format(event)}
            
        </Box>       
    ))  
}

export default EventsWidget;