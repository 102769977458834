import React from "react";
import { Box, Typography } from "@mui/material";
import logoImage from "./image/logo-white.png";

const SportFlixInfo = () => {
  return (
    <Box
        mt={2}
      sx={{
        backgroundColor: "#0e0e16",
        color: "#fff",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        textAlign: "center",
        lineHeight: 1.6,
      }}
    >
        <Box
              component="img"
              src={logoImage}
              alt="sportflix"
              sx={{ width: 250, height: "auto", borderRadius: 2 }}
            />
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        SportFlix เว็บเช็กผลบอล Livescore อัปเดตสกอร์สด ครบทุกลีก!
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        SportFlix ให้คุณ เช็กผลบอลแบบเรียลไทม์ รู้ผลการแข่งขันก่อนใคร ครอบคลุมทุกลีกดังทั่วโลก ไม่ว่าจะเป็น
        พรีเมียร์ลีก อังกฤษ, ลาลีกา สเปน, เซเรีย อา อิตาลี, บุนเดสลีกา เยอรมัน, ลีกเอิง ฝรั่งเศส และลีกอื่น ๆ
        อีกมากมาย พร้อมข้อมูลการแข่งขันที่อัปเดตตลอด 24 ชั่วโมง
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        เช็ก ผลบอลสด ได้แบบเรียลไทม์ พร้อม ผลบอลย้อนหลัง ที่มาพร้อมสถิติการแข่งขันอย่างละเอียด นอกจากนี้ยังมี
        โปรแกรมบอลล่วงหน้า ให้คุณติดตามตารางแข่งได้ทุกวัน รวมถึงข้อมูลเชิงลึกของทีมและนักเตะ เพื่อช่วยวิเคราะห์เกมก่อนการแข่งขัน
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        SportFlix Livescore ใช้งานง่าย รองรับทุกอุปกรณ์ ไม่ว่าจะเป็นคอมพิวเตอร์ แท็บเล็ต หรือมือถือ อัปเดตไว
        โหลดเร็ว ไม่มีสะดุด รู้ผลบอลก่อนใคร ต้องที่ SportFlix!
      </Typography>
    </Box>
  );
};

export default SportFlixInfo;
