import React, { createContext, useState, useMemo, useEffect } from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";

// Create a context for theme
export const ThemeContext = createContext();

export function ThemeProviderWrapper({ children }) {
  // Load dark mode preference from localStorage
  //const storedTheme = localStorage.getItem("darkMode") === "true";
  const [darkMode, setDarkMode] = useState(true);

  // Save dark mode setting to localStorage
  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  // Create a theme based on dark/light mode
  const theme = useMemo(() => createTheme({
    palette: {      
      mode: darkMode ? "dark" : "light",
      background: { default: darkMode ? "#121212" : "#f5f5f5" },
      text: { primary: darkMode ? "#ffffff" : "#000000" },
    },
    typography: {
      caption: {
        fontSize: "0.75rem", // Default MUI caption size
        "@media (max-width:600px)": { fontSize: "0.6rem" }, // Mobile override
      },
      body1: {
        fontSize: "1rem", // Default MUI caption size
        "@media (max-width:600px)": { fontSize: "0.75rem" }, // Mobile override
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          sizeSmall: {            
            "@media (max-width:600px)": { // Mobile-specific styles
              fontSize: "0.6rem",              
            },
          },
        },
      },      
    },
  }), [darkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}