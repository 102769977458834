import React, { useEffect, useState, useContext,Suspense } from 'react';
import axios from 'axios';
import {
  CircularProgress,
  Typography, 
  Box, 
  Stack, 
  Avatar, 
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab,
  Paper,
  Button,
  ButtonGroup,
  useMediaQuery } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTime } from 'luxon';
import { ThemeContext } from "./ThemeContext";
import { useTheme } from "@mui/material/styles";
import BlinkingDot from "./BlinkingDot.js";
import Img188bet from "./image/188.png";
import LineupWidget from './LineupWidget.js';
import EventsWidget from './EventsWidget.js';
import CommentIcon from '@mui/icons-material/Comment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import BtnJson from './btn.json';
import MatchesContent from './MatchesWidget.js';

const Fixture = ({view,selectedDate,handleAllleague,selectedLeague,search}) => {
  const [loading, setLoading] = useState(false); 
  const { darkMode } = useContext(ThemeContext); // Get theme mode
  const [viewdata, setViewdata] = useState([]); 
  
  const [matches, setMatches] = useState([]); 
 
  const [activeTabs, setActiveTabs] = useState({});
  const [selectedButtonfilter, setSelectedButtonfilter] = useState(null);

  const handleFilter = async (league) => {    
    setSelectedButtonfilter(selectedButtonfilter?.id === league.id ? null : league);    
  };
    
  const handleChange = (groupId, newValue) => {
    setActiveTabs((prev) => ({ ...prev, [groupId]: newValue }));
  };
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));  // Mobile
  // Lazy-load components for each tab
  const TimelineContent = React.lazy(() => import('./TimelineWidget.js'));
  const Head2headContent = React.lazy(() => import('./Head2headWidget.js'));
  const StandingContent = React.lazy(() => import('./StandingWidget.js'));
  //const MatchesContent = React.lazy(() => import('./MatchesWidget.js'));
  // const TabContent4 = React.lazy(() => import('./TabContent4'));
  
  const isStart = (starting_at) =>{
    const dt1 = DateTime.now().setZone('UTC');
    const dt2 = DateTime.fromSQL(starting_at, { zone: 'UTC' });    
    return dt1>dt2 || dt1.equals(dt2);
  }
  
  const matchStatus = (periods,starting_at,winner) => {
    const minute = periods?.find((p)=>p.has_timer);
    if(winner === true || winner === false) 
      return <Typography variant="caption" sx={{ textAlign: "left" }}>FT</Typography>;
    else if(!isStart(starting_at))
      return <Typography variant="caption" sx={{ textAlign: "left" }}>NS</Typography>;
    else if(periods && periods.length>0 && minute){
      return (
        <Stack direction="row" >
          <Typography variant="body1" sx={{ color:"#809bce", fontWeight: "bold", textAlign: "left" }}>{minute.minutes}</Typography>
          <BlinkingDot/>
          <Typography variant="caption" sx={{ color:"#809bce", fontWeight: "bold", textAlign: "left" }}>{minute.time_added && `+${minute.time_added}`}</Typography>
        </Stack>        
      );      
    }
    else if(periods && periods.length===1 && !minute)
      return <Typography variant="caption" sx={{ textAlign: "left" }}>HT</Typography>;   
    else return <Typography variant="caption" sx={{ textAlign: "left" }}></Typography>;
  }

  const formatTime = (starting_at) =>{
    const dt = DateTime.fromSQL(starting_at, { zone: 'UTC' }); 
    return dt.setZone(DateTime.now().zoneName).toFormat('HH:mm');   
  }  


  useEffect(() => {
    const fetchMatchesFromDate = async () => {
      try 
      {               
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/fixtures`, 
        {
          date: selectedDate,
          timezone: DateTime.now().toFormat('ZZ')
        });        
        setMatches(response.data);      
      } catch (error) {
        console.error('Error fetching live scores:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchMatchesFromLeague = async (league) => {
      try 
      {                      
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/fixtures-by-league`, 
        {
          date: DateTime.now().toFormat("yyyy-MM-dd"),
          timezone: DateTime.now().toFormat('ZZ'),
          league_id: league.id,
          country_id: league.country_id
        });        
        let prevLeagueName = null;
        let fixtures_add_group = [];
        const sorted = response.data.sort((a, b) => a.starting_at.localeCompare(b.starting_at));
        sorted.forEach((item) => {
          const key = `${item.starting_at.split(" ")[0]}`;
          if (key !== prevLeagueName) {
            fixtures_add_group.push({...item,group:true});
          }else{
            fixtures_add_group.push({...item,group:false});
          }        
          prevLeagueName = key;
        });     
        
        setViewdata(fixtures_add_group);      
      } catch (error) {
        console.error('Error fetching live scores:', error);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    };

    setLoading(true);
    if(selectedButtonfilter === null){
      fetchMatchesFromDate();
      const interval = setInterval(fetchMatchesFromDate, 60000); // Refresh every 60 seconds
      return () => clearInterval(interval);
    }else{
      fetchMatchesFromLeague(selectedButtonfilter);
      const interval = setInterval(() => {
        fetchMatchesFromLeague(selectedButtonfilter);
      }, 60000); // Refresh every 60 seconds
      return () => clearInterval(interval);
    }
  }, [selectedDate,selectedButtonfilter]);

  useEffect(() => {   
    const modify = (fixtures_data) => {
      const targetLeage = [
        {league: "Premier League",country: "England"},
        {league: "La Liga",country: "Spain"},
        {league: "Budes Liga",country: "German"},
        {league: "Serie A",country: "Italy"},
        {league: "UEFA Chaimpions Leauge",country: ""},
        {league: "EUROPA Leauge",country: ""}      
      ];
      
      const first = targetLeage.map(i => {
        if(i.country === "") {
          return fixtures_data.filter(item => item.league.name === i.league).sort((a, b) => b.league.name.localeCompare(a.league.name) || a.league.country_name.localeCompare(b.league.country_name) || a.starting_at.localeCompare(b.starting_at));
        } else {
          return fixtures_data.filter(item => item.league.name === i.league && item.league.country_name === i.country).sort((a, b) => a.league.name.localeCompare(b.league.name) || a.league.country_name.localeCompare(b.league.country_name) || a.starting_at.localeCompare(b.starting_at));
        }
      });
      
      const firstMerged = first.flat(); 
      const secondMerged = fixtures_data.sort((a, b) => a.league.name.localeCompare(b.league.name) || a.league.country_name.localeCompare(b.league.country_name) || a.starting_at.localeCompare(b.starting_at));
           
      const mergedJson = Array.from(
        new Set([...firstMerged, ...secondMerged].map(JSON.stringify)) // แปลงเป็น string และเก็บเป็น Set
      ).map(JSON.parse); // แปลงกลับเป็น object

      // แยกออกเป็น 2 กลุ่ม
      //const first = fixtures_data.filter(item => targetLeage.includes(item.league.name) && targetCountry.includes(item.league.country_name)); // ตัดออก
      //const second = fixtures_data.filter(item => !targetLeage.includes(item.league.name)); // เก็บไว้

      //const first_sort = [...first].sort((a, b) => b.league.name.localeCompare(a.league.name) || a.league.country_name.localeCompare(b.league.country_name) || a.starting_at.localeCompare(b.starting_at));
      //const second_sort = [...second].sort((a, b) => a.league.name.localeCompare(b.league.name) || a.league.country_name.localeCompare(b.league.country_name) || a.starting_at.localeCompare(b.starting_at));
      // รวมกลับ
      const sorted = mergedJson;

      //const sorted = [...fixtures_data].sort((a, b) => a.league.name.localeCompare(b.league.name) || a.league.country_name.localeCompare(b.league.country_name) || b.starting_at.localeCompare(a.starting_at));
      let prevLeagueName = null;
      let fixtures_add_group = [];
      sorted.forEach((item) => {
        const key = `${item.league.name}-${item.league.country_name}`;
        if (key !== prevLeagueName) {
          fixtures_add_group.push({...item,group:true});
        }else{
          fixtures_add_group.push({...item,group:false});
        }        
        prevLeagueName = key;
      });      

      return fixtures_add_group;
    };
    if(matches.length > 0){
      let viewDataModify = [];
      if(view === "all"){  
        const modifyData = modify(matches); 
        if(selectedLeague === "All league") { 
          if(search === ""){ 
            viewDataModify = modifyData;
            setViewdata(viewDataModify);
          }
          else{
            viewDataModify = modifyData.filter((item) => item.team_home.name.toLowerCase().includes(search.toLowerCase()) || item.team_away.name.toLowerCase().includes(search.toLowerCase()));
            setViewdata(viewDataModify);
          }
        }
        else{
          if(search === ""){ 
            viewDataModify = modifyData.filter((item) => item.league.name===selectedLeague);
            setViewdata(viewDataModify);
          }
          else{
            viewDataModify = modifyData.filter((item) => item.league.name===selectedLeague && (item.team_home.name.toLowerCase().includes(search.toLowerCase()) || item.team_away.name.toLowerCase().includes(search.toLowerCase())));
            setViewdata(viewDataModify);
          }
        }        
        handleAllleague(modifyData.filter((item) => item.group===true)); 
      }
      if(view === "live"){
        const modifyData = modify(matches.filter((item) => item.livescore===true )); 
        if(selectedLeague === "All league") { 
          if(search === ""){
            viewDataModify = modifyData;
            setViewdata(viewDataModify);
          }
          else{
            viewDataModify = modifyData.filter((item) => item.team_home.name.toLowerCase().includes(search.toLowerCase()) || item.team_away.name.toLowerCase().includes(search.toLowerCase()));
            setViewdata(viewDataModify);
          }
        }else{
          if(search === ""){
            viewDataModify = modifyData.filter((item) => item.league.name===selectedLeague);
            setViewdata(viewDataModify);
          }
          else{
            viewDataModify = modifyData.filter((item) => item.league.name===selectedLeague && (item.team_home.name.toLowerCase().includes(search.toLowerCase()) || item.team_away.name.toLowerCase().includes(search.toLowerCase())));
            setViewdata(viewDataModify);
          }
        }        
        handleAllleague(modifyData.filter((item) => item.group===true));        
      }
      
    }else{
      setViewdata([]);
    }
   //console.log(viewdata);
  }, [view,selectedLeague, search, matches, handleAllleague]); 
  
  return (
    <>
      {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography variant="h6" ml={2}>Loading Matches...</Typography>
      </Box>
    ) :(
      <>      
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
        {BtnJson.map((league) => (
          <Button 
          key={league.id} 
          variant={selectedButtonfilter?.id === league.id ? "contained" : "outlined"}           
          onClick={() => handleFilter(league)}
          disabled={loading}
          >            
              <Avatar
                src={league.image_path}
                alt={league.name}
                sx={{ width: isMobile?30:40, height: isMobile?30:40, borderRadius: "20%", background:"#d1d1d1" }}
              />
            
          </Button>
        ))}
        </ButtonGroup>
      </Stack>
      
      {viewdata.map((match, sectionIndex) => (
        <React.Fragment key={`fragment-${sectionIndex}`}>
          {match.group && (
            <Box
              key={`group-${sectionIndex}`}
              display="flex"              
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{
                backgroundColor: darkMode ? " #121212" : "",
                border: darkMode ? "1px solid #575757" : "1px solid #d1d1d1",
                padding: 2,
                marginTop: 1,
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1} sx={{width:"50%"}}>
                <Avatar
                  src={match.league.image_path}
                  alt={match.league.name}
                  sx={{ width: isMobile?30:40, height: isMobile?30:40, borderRadius: "20%", background:"#d1d1d1" }}
                />
                <Stack direction="column">
                  <Typography align='left' variant="body1">{match.league.name}</Typography>
                  <Typography align='left' variant="caption">{match.league.country_name}</Typography>
                </Stack>                
              </Stack>
              <Stack direction="column" sx={{width:"50%"}}>
                  <Typography align='right' variant="body1">{isMobile? DateTime.fromSQL(match.starting_at, { zone: 'utc' }).setZone(DateTime.now().zoneName).toFormat("d LLL yy"):DateTime.fromSQL(match.starting_at, { zone: 'utc' }).setZone(DateTime.now().zoneName).toFormat("d LLLL ''yy")}</Typography>
                  <Typography align='right' variant="caption">{match.round.name !== '' && `Round ${match.round.name}`}</Typography>
              </Stack>
              
            </Box>
          )}
  
          <Accordion
            key={`accordion-${sectionIndex}`}
            disableGutters
            sx={{
              boxShadow: "none",
              borderLeft: darkMode ? "1px solid #575757" : "1px solid #d1d1d1",
              borderRight: darkMode ? "1px solid #575757" : "1px solid #d1d1d1",
              borderBottom: darkMode ? "1px solid #575757" : "1px solid #d1d1d1",
            }}
          >
            <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
              <Box
                key={`summary-${sectionIndex}`}
                display="flex"
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
                gap={2}
                mb={4}
                sx={{ padding: 0, margin: 0 }}
              >
                {isMobile ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ width: "45%", margin: 0 }}
                  >                    
                    {matchStatus(match.periods,match.starting_at,match.team_home.winner)} 
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      sx={{ width: isMobile ? 100 : 250, margin: 0 }}
                    >
                      <Avatar
                        src={match.team_home.image_path}
                        alt={match.team_home.name}
                        sx={{ width: 30, height: 30, borderRadius: "50%" }}
                      />
                      <Typography variant="caption" sx={{ textAlign: "center" }}>
                        {match.team_home.name}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ width: "45%", margin: 0 }}
                  >                    
                    {matchStatus(match.periods,match.starting_at,match.team_home.winner)}                    
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="caption" sx={{ textAlign: "right" }}>
                        {match.team_home.name}
                      </Typography>
                      <Avatar
                        src={match.team_home.image_path}
                        alt={match.team_home.name}
                        sx={{ width: 40, height: 40, borderRadius: "50%" }}
                      />
                    </Stack>
                  </Stack>
                )}
  
                {isStart(match.starting_at) ? (
                  <Chip
                    label={`${match.team_home.score} - ${match.team_away.score}`}
                    color="success"
                    
                    sx={{ backgroundColor: "#65a8de", mt: 1, margin: 0 }}
                  />
                ) : (
                  <Chip
                    label={`${formatTime(match.starting_at)}`}
                    color="success"
                    
                    sx={{ backgroundColor: "#65a8de", mt: 1, margin: 0 }}
                  />
                )}
  
                {isMobile ? (
                  <Stack
                    direction="column"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: isMobile ? 100 : 250 }}
                  >
                    <Avatar
                      src={match.team_away.image_path}
                      alt={match.team_away.name}
                      sx={{ width: 30, height: 30, borderRadius: "50%" }}
                    />
                    <Typography variant="caption" sx={{ textAlign: "center" }}>
                      {match.team_away.name}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: "45%"}}
                  >
                    <Avatar
                      src={match.team_away.image_path}
                      alt={match.team_away.name}
                      sx={{ width: 40, height: 40, borderRadius: "50%" }}
                    />
                    <Typography variant="caption">{match.team_away.name}</Typography>
                  </Stack>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Paper elevation={0} sx={{ flexGrow: 1,backgroundColor: "inherit" }}>
                <TabContext value={activeTabs[match.fixture_id]?activeTabs[match.fixture_id]:"1"}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList centered onChange={(event, newValue) => handleChange(match.fixture_id, newValue)} aria-label={`Tabs for ${match.fixture_id}`}>
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<Avatar sx={{ width: 22, height: 22, fontSize: 8 }}>ODD</Avatar>} label={isMobile?"":"Odds"} value="1" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<Avatar sx={{ width: 22, height: 22, fontSize: 8 }}>LINE</Avatar>} label={isMobile?"":"Lineup"} value="2" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<EventNoteIcon />} label={isMobile?"":"Events"} value="3" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<CommentIcon />} label={isMobile?"":"Comments"} value="4" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<Avatar sx={{ width: 22, height: 22, fontSize: 8 }}>H2H</Avatar>} label={isMobile?"":"Head2head"} value="5" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<EmojiEventsIcon />} label={isMobile?"":"Standing"} value="6" />
                      <Tab sx={{ minWidth: isMobile ? 0 : 90, padding: isMobile ? "4px" : "12px" }} icon={<SportsSoccerIcon />} label={isMobile?"":"Matches"} value="7" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <a href="https://4254sport.com/go" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <img src={Img188bet} alt="188" height={30}/>
                      <Chip avatar={<Avatar>1</Avatar>} label={match.odds.home} />
                      <Chip avatar={<Avatar>X</Avatar>} label={match.odds.draw} />
                      <Chip avatar={<Avatar>2</Avatar>} label={match.odds.away} />
                    </Stack>
                    </a>
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: { xs: 0, sm: 2, md: 4 }}}>                    
                    { match.team_home.formations && match.team_away.formations &&
                      <LineupWidget 
                        key={`LineupWidget-${sectionIndex}`} 
                        id={`LineupWidget-${sectionIndex}`}                                                  
                        formation_home={match.team_home.formations} 
                        lineups_home={match.team_home.lineups} 
                        formation_away={match.team_away.formations} 
                        lineups_away={match.team_away.lineups}/> 
                    }                      
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: { xs: 0, sm: 2, md: 4 }}}>
                    <EventsWidget key={`EventsWidget-${sectionIndex}`}  mode={darkMode} events={match.events}/>
                  </TabPanel>
                  <TabPanel value="4" sx={{ padding: { xs: 0, sm: 2, md: 4 }}}>
                    <Suspense fallback={<CircularProgress />}>
                      <TimelineContent key={`TimelineContent-${sectionIndex}`} fixture_id={match.fixture_id} comments={match.comments}/>
                    </Suspense>                    
                  </TabPanel>
                  <TabPanel value="5" sx={{ padding: { xs: "20px 0 0 0", sm: 2, md: 4 }}}>
                    <Suspense fallback={<CircularProgress />}>
                      <Head2headContent key={`Head2headContent-${sectionIndex}`} team_home={match.team_home} team_away={match.team_away} isMobile={isMobile}/>
                    </Suspense>                    
                  </TabPanel>
                  <TabPanel value="6" sx={{ padding: { xs: "20px 0 0 0", sm: 2, md: 4 }}}>
                    <Suspense fallback={<CircularProgress />}>
                      <StandingContent key={`StandingContent-${sectionIndex}`} league_id={match.league.id} isMobile={isMobile} team_home_id={match.team_home.id} team_away_id={match.team_away.id}/>
                    </Suspense>                    
                  </TabPanel>
                  <TabPanel value="7" sx={{ padding: { xs: "20px 0 0 0", sm: 2, md: 4 }}}>                    
                      <MatchesContent key={`MatchesContent-${sectionIndex}`} isMobile={isMobile} team_home_id={match.team_home.id} team_away_id={match.team_away.id}/>               
                  </TabPanel>
                </TabContext>               
              </Paper>
            </AccordionDetails>
          </Accordion>          
        </React.Fragment>
      ))}
      </>
      )}      
    </>
  );
  
};

export default Fixture;
